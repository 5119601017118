export default function (btn, nav) {
    const navbar = $(nav)

    $(btn).on('click', function () {
        if ( navbar.hasClass('active') ){
            navbar.animate({right: '-1rem', opacity: 0}, 300,
                function () {
                    $(this).addClass('hidden').removeClass('active')
                })

            $(this).html('<i class="fa fa-bars"></i>')
            return true
        }


        navbar.removeClass('hidden')
            .animate({right: 0, opacity: 1}, 300)
            .addClass('active')

        $(this).html('<i class="fa fa-times"></i>')
    })

    navbar.find('.dropdown').on('click', '.btn-dropdown',function (e) {
        e.preventDefault();
        $(this).parent().find('ul').slideToggle()
    })
}