// Dependencies
import 'owl.carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'

// Assets
import '../assets/img/experience/bg.jpg'
import '../assets/img/experience/project-1.jpg'
import '../assets/img/experience/project-2.jpg'
import '../assets/img/experience/project-3.jpg'
import '../assets/img/experience/project-4.jpg'
import '../assets/img/experience/project-5.jpg'

/**
 * DOC Ready
 */
$(document).ready(function () {
    /**
     * Carousel de Experiencia
     */
    $('.owl-carousel.old-projects').owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        dots: true,
        animateOut: 'fadeOut fast',
        animateIn: 'fadeIn',
        mouseDrag: false,
        touchDrag: false
    })
})