// Stylesheets
import './assets/css/animate.css'
import './assets/scss/main.scss'

// Files
import './assets/humans.txt'
import './assets/robots.txt'
import './assets/site.webmanifest'
import './assets/browserconfig.xml'

// Images
import './assets/img/common/logo.svg'
import './assets/img/common/logo-blanco.png'
import './assets/img/common/heart.png'
import './assets/img/common/thumbs.png'
import './assets/tile.png'
import './assets/tile-wide.png'

// Components
import nav from './app/components/nav'
import formContact from './app/components/formContact'

// Vendors
import WOW from 'wowjs'
import './assets/js/fontawesome'

// Sections
import './app/experience'

$(document).ready(function () {
    // Instancia el main nav
    nav('#btnMainNav', '#mainNav')

    // Instancia el formulario de contacto
    formContact()

    // instancia wow
    new WOW.WOW().init()
})