export default function () {
    $('#form-contact').on('submit', function (e) {
        e.preventDefault()

        /**
         * Encargada de llevar un control de errores
         * @type {*[]}
         */
        let errors = []

        /**
         * Instancia la función que hace referencia al feedback
         */
        const feedback = Feedback($(this))


        // Validar el nombre
        if ( validate($(this).find('[name="name"]')) ){
            errors.push('Nombre')
        }

        // Valida el asunto
        if ( validate($(this).find('[name="affair"]')) ){
            errors.push('Asunto')
        }

        // Valida el email
        if ( validate($(this).find('[name="email"]'), 'email') ){
            errors.push('Email')
        }

         // Valida el mensaje
        if ( validate($(this).find('[name="message"]')) ){
            errors.push('Mensaje')
        }

        // Si alguna validación fallo entrara aquí
        if ( errors.length ){
            feedback.html(`
                <div class="text-center text-gray-1" role="alert">
                    <p class="text-lg mb-4">Los siguientes campos son incorrectos <strong>${errors.join(', ')}</strong>. Por favor, vuelve a intentarlo.</p>
                    <button type="button" class="btn-secondary" id="remove-feedback" role="button" aria-label="Close">Bien</button>
                </div>`)
            return false
        }

        console.log('DATOS SIENDO ENVIADOS A: ', $('#app_url').attr('href') + 'api/send-message.php')
        /**
         * Envió del mail por ajax
         */
        $.ajax({
            method: 'POST',
            data: $(this).serialize(),
            url: $('#app_url').attr('href') + 'api/send-message.php',
            dataType: 'json'
        })
            // Se pudo completar la petición
            .then((data, textStatus, jqXHR)=>{
                feedback.html(`
                    <div class="text-center text-gray-1" role="alert">
                         <h4 class="text-2xl">${data.title}</h4>
                         <h6 class="text-xl mb-3">${data.message}</h6>
                    </div>`)

                if ( data.status ){
                    feedback.find('div').prepend('<i class="fa fa-check fa-4x mb-4 text-gray-2"></i>')
                }else{
                    feedback.find('div').prepend('<i class="fa fa-times-circle fa-4x mb-4 text-red"></i>')
                    feedback.find('div').append('<button type="button" class="btn-secondary" id="remove-feedback">Intentarlo se nuevo</button>')
                }
            })
            // Falla la conexión
            .fail((jqXHR, textStatus, errorThrown)=>{
                feedback.html(`
                    <div class="text-center text-gray-1" role="alert">
                        <i class="fa fa-times-circle fa-4x mb-4 text-red"></i>
                        <p class="text-xl mb-1">${jqXHR.status} ${errorThrown}</p>
                        <p class="text-lg mb-5">Por favor vuelve a intentarlo</p>
                        <button type="button" class="btn-secondary" id="remove-feedback">Bien</button>
                    </div>`)
            })
    })
}

/**
 * Valida los campos pasados
 * Devolverá un true si el campo es invalido
 *
 * @param text
 * @param type
 * @returns {boolean}
 */
const validate = (text, type = 'text') => {
    if ( text.val().length < 3 ){
        return true;
    }

    if ( type === 'email' ){
        return text.val().indexOf('@') === -1
    }

    return false
}

/**
 * Muestra información al usuario con respecto al estado de su formulario
 * @param form
 */
const Feedback = form => {
    // Oculta los campos inicialmente
    form.find('>div').addClass('opacity-0')

    /**
     * Preload que se muestra cargando en el formulario
     * Este cambiara conforme avance el programa
     * @type {*|jQuery}
     */
    const preload = $('<div>', {
        class: 'absolute w-full h-full flex items-center justify-center',
        id: 'info-formContact',
        role: 'alert'
    }).html('<i class="fas fa-cog fa-spin fa-4x text-red"></i>').appendTo(form)

    /**
     * Añade el evento para cerrar el feedback
     */
    preload.on('click', '#remove-feedback', function (e) {
        e.preventDefault()
        e.stopPropagation()
        preload.remove()
        form.find('>div').removeClass('opacity-0')
        $(this).off('click')
    })

    return preload
}